var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"carsIntroduction-overview-warpper"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('section',{staticClass:"autoshow-section"},[_c('div',{staticClass:"title"},[_vm._v("U-POWER 超高速充電")]),_c('div',{staticClass:"subtitle"},[_vm._v("線上車展搶先看")]),_c('div',{staticClass:"slide-container"},[_vm._m(3),_c('div',{staticClass:"slide-box"},[_c('swiper',{attrs:{"breakpoints":_vm.swiperBreakPoints,"slides-per-view":1,"space-between":30,"loop":true,"centeredSlides":true,"pagination":_vm.swiperConfig,"autoplay":{
            delay: 2500,
            disableOnInteraction: false,
          }}},[_c('swiper-slide',[_c('div',{staticClass:"autoshow-slide-photo"},[_c('img',{attrs:{"src":require("../../assets/images/autoshow-slide-01.png"),"alt":""}})])]),_c('swiper-slide',[_c('div',{staticClass:"autoshow-slide-photo"},[_c('img',{attrs:{"src":require("../../assets/images/autoshow-slide-02.png"),"alt":""}})])]),_c('swiper-slide',[_c('div',{staticClass:"autoshow-slide-photo"},[_c('img',{attrs:{"src":require("../../assets/images/autoshow-slide-03.png"),"alt":""}})])]),_c('swiper-slide',[_c('div',{staticClass:"autoshow-slide-photo"},[_c('img',{attrs:{"src":require("../../assets/images/autoshow-slide-04.png"),"alt":""}})])]),_c('ul',{staticClass:"controller-autoshow"})],1)],1)]),_vm._m(4)]),_c('section',{staticClass:"map-section"},[_c('div',{staticClass:"map-box"},[_c('img',{staticClass:"map",attrs:{"src":require("../../assets/images/station-map.png"),"alt":""},on:{"click":_vm.openImg}}),_c('img',{staticClass:"search-icon",attrs:{"src":require("../../assets/icon/svg/search.svg"),"alt":""},on:{"click":_vm.openImg}})])]),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require("../../assets/images/hero-img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-container mobile"},[_c('div',{staticClass:"main-logo"},[_c('img',{attrs:{"src":require("../../assets/images/main-logo.png"),"alt":""}})]),_c('div',{staticClass:"hero-img"},[_c('img',{attrs:{"src":require("../../assets/images/hero-img-origin.png"),"alt":""}})]),_c('div',{staticClass:"event-date mobile"},[_vm._v(" 2023/12/28 – 2024/1/1 台北南港展覽館 1 館 1 樓 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-info-section"},[_c('div',{staticClass:"title"},[_vm._v("U-POWER X 2024 臺北新車大展")]),_c('div',{staticClass:"subtitle"},[_vm._v("邁入 EV 2.0 時代 講究充電速度與效率世代來臨")]),_c('div',{staticClass:"subtitle-small"},[_vm._v(" 迎向電動車浪潮，準備好擁抱電能新生活 "),_c('br'),_vm._v(" 好想買電動車，卻因無法裝充電樁而卻步 ")]),_c('div',{staticClass:"bricks-img"},[_c('img',{attrs:{"src":require("../../assets/images/bricks.png"),"alt":""}})]),_c('div',{staticClass:"desc"},[_c('p',[_vm._v(" U-POWER 超高速充電是電動車充電營運商的領導品牌，經營專屬場域的大型化超高速充電站，致力於提供電動車主頂級的充電體驗。在睽違 4 年的 2024 臺北新車大展中，U-POWER 將帶給車迷們車展史上最特別的跨年驚喜，把 1:1 完整比例總功率 360kW 超高速充電站搬進南港展覽館中！ ")]),_c('br'),_c('br'),_c('p',[_vm._v(" U-POWER 團隊將不藏私地，把我們追求 100% 頂級充電體驗、實現原廠充電功率、與建置超高速充電站的初衷與堅持、以及對使用電動車充電的熱情與知識，完整帶到 2024 臺北新車大展。無論您已入主電動車，或是還在觀望但因為充電問題苦惱卻步，都值得您親身參與這歷史性的活動，到臺北車展 U-POWER 展區來認識我們！ ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" 追求 100% 頂級充電體驗 "),_c('img',{attrs:{"src":require("../../assets/images/small-word-01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"station-introduction-container"},[_c('div',{staticClass:"title"},[_vm._v(" 全臺最多! 96 支液冷槍 "),_c('img',{attrs:{"src":require("../../assets/images/small-word-02.png"),"alt":""}})]),_c('div',{staticClass:"desc"},[_c('p',[_vm._v(" 能夠承載 500A(安培)的液冷槍，才能實現原廠宣稱的充電功率，U-POWER 大型充電站，就像是航空母艦，所有站點都配置液冷槍，是關鍵的核彈級戰略裝備。U-POWER 目前總共裝置了92支液冷槍，是全臺第三方充電業者數量最多的，加上即將上線營運的彰化、嘉義、鳳山、屏東等站，液冷槍數量將達到 118 支。 ")])]),_c('div',{staticClass:"charging-table"},[_c('img',{attrs:{"src":require("../../assets/images/charging-table.png"),"alt":""}})]),_c('div',{staticClass:"contents-box"},[_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/content-01.png"),"alt":""}})]),_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/content-02.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cooperation-section"},[_c('div',{staticClass:"title"},[_vm._v("合作夥伴")]),_c('div',{staticClass:"logos"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_BMW.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Ford.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Hyundai.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_KIA.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Lexus.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Luxgen.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Benz.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_Ssangyong.svg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../assets/icon/svg/Logo_VOLVO.svg"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"footer-section"},[_c('div',{staticClass:"footer-container"},[_vm._v(" ©2023 EVGallop Mobility System Corporation. All Rights Reserved. ")])])
}]

export { render, staticRenderFns }