<template>
  <div id="carsIntroduction-overview-warpper">
    <div class="img-container">
      <img src="../../assets/images/hero-img.png" alt="" />
    </div>
    <div class="img-container mobile">
      <div class="main-logo">
        <img src="../../assets/images/main-logo.png" alt="" />
      </div>
      <div class="hero-img">
        <img src="../../assets/images/hero-img-origin.png" alt="" />
      </div>
      <div class="event-date mobile">
        2023/12/28 – 2024/1/1 台北南港展覽館 1 館 1 樓
      </div>
    </div>
    <section class="main-info-section">
      <div class="title">U-POWER X 2024 臺北新車大展</div>
      <div class="subtitle">邁入 EV 2.0 時代 講究充電速度與效率世代來臨</div>
      <div class="subtitle-small">
        迎向電動車浪潮，準備好擁抱電能新生活 <br />
        好想買電動車，卻因無法裝充電樁而卻步
      </div>
      <div class="bricks-img">
        <img src="../../assets/images/bricks.png" alt="" />
      </div>
      <div class="desc">
        <p>
          U-POWER
          超高速充電是電動車充電營運商的領導品牌，經營專屬場域的大型化超高速充電站，致力於提供電動車主頂級的充電體驗。在睽違
          4 年的 2024 臺北新車大展中，U-POWER
          將帶給車迷們車展史上最特別的跨年驚喜，把 1:1 完整比例總功率 360kW
          超高速充電站搬進南港展覽館中！
        </p>
        <br />
        <br />
        <p>
          U-POWER 團隊將不藏私地，把我們追求 100%
          頂級充電體驗、實現原廠充電功率、與建置超高速充電站的初衷與堅持、以及對使用電動車充電的熱情與知識，完整帶到
          2024
          臺北新車大展。無論您已入主電動車，或是還在觀望但因為充電問題苦惱卻步，都值得您親身參與這歷史性的活動，到臺北車展
          U-POWER 展區來認識我們！
        </p>
      </div>
    </section>
    <section class="autoshow-section">
      <div class="title">U-POWER 超高速充電</div>
      <div class="subtitle">線上車展搶先看</div>
      <!-- slide section -->
      <div class="slide-container">
        <div class="title">
          追求 100% 頂級充電體驗
          <img src="../../assets/images/small-word-01.png" alt="" />
        </div>
        <div class="slide-box">
          <swiper
            :breakpoints="swiperBreakPoints"
            :slides-per-view="1"
            :space-between="30"
            :loop="true"
            :centeredSlides="true"
            :pagination="swiperConfig"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
          >
            <swiper-slide>
              <div class="autoshow-slide-photo">
                <img src="../../assets/images/autoshow-slide-01.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="autoshow-slide-photo">
                <img src="../../assets/images/autoshow-slide-02.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="autoshow-slide-photo">
                <img src="../../assets/images/autoshow-slide-03.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="autoshow-slide-photo">
                <img src="../../assets/images/autoshow-slide-04.png" alt="" />
              </div>
            </swiper-slide>
            <ul class="controller-autoshow"></ul>
          </swiper>
        </div>
      </div>
      <!-- station introduction section -->
      <div class="station-introduction-container">
        <div class="title">
          全臺最多! 96 支液冷槍
          <img src="../../assets/images/small-word-02.png" alt="" />
        </div>
        <div class="desc">
          <p>
            能夠承載 500A(安培)的液冷槍，才能實現原廠宣稱的充電功率，U-POWER
            大型充電站，就像是航空母艦，所有站點都配置液冷槍，是關鍵的核彈級戰略裝備。U-POWER
            目前總共裝置了92支液冷槍，是全臺第三方充電業者數量最多的，加上即將上線營運的彰化、嘉義、鳳山、屏東等站，液冷槍數量將達到
            118 支。
          </p>
        </div>
        <div class="charging-table">
          <img src="../../assets/images/charging-table.png" alt="" />
        </div>
        <div class="contents-box">
          <div class="content">
            <img src="../../assets/images/content-01.png" alt="" />
          </div>
          <div class="content">
            <img src="../../assets/images/content-02.png" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="map-section">
      <div class="map-box">
        <img
          @click="openImg"
          class="map"
          src="../../assets/images/station-map.png"
          alt=""
        />
        <img
          @click="openImg"
          class="search-icon"
          src="../../assets/icon/svg/search.svg"
          alt=""
        />
      </div>
    </section>
    <section class="cooperation-section">
      <div class="title">合作夥伴</div>
      <div class="logos">
        <ul>
          <li>
            <img src="../../assets/icon/svg/Logo_BMW.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Ford.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Hyundai.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_KIA.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Lexus.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Luxgen.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Benz.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_Ssangyong.svg" alt="" />
          </li>
          <li>
            <img src="../../assets/icon/svg/Logo_VOLVO.svg" alt="" />
          </li>
        </ul>
      </div>
    </section>
    <section class="footer-section">
      <div class="footer-container">
        ©2023 EVGallop Mobility System Corporation. All Rights Reserved.
      </div>
    </section>

    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
    <!-- router to Cars-Introduction -->
  </div>
</template>
<script>
import { Pagination, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "CarsIntroduction-overview",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiperBreakPoints: {
        512: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        880: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      swiperConfig: {
        el: ".controller-autoshow",
        clickable: true,
        renderBullet: function (index, className) {
          return '<li class="' + className + '"></li>';
        },
      },
    };
  },
  methods: {
    openImg() {
      window.open(
        "https://www.u-power.com.tw/photo/station-map-origin.png",
        "_blank"
      );
    },
    handleRedirect(type) {
      if (type === "form") {
        window.open("https://forms.gle/RuYZWmR17E2jqs4k9", "_blank");
        return;
      }
      if (type === "event") {
        window.open(
          "https://www.facebook.com/100083304310718/posts/pfbid02kjuJV23K8mMkNjEu62Gr3JYiWr6of7V6Yqz7aHojyCj7DZPvRYzKKgDcgB7NpWqbl/",
          "_blank"
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#carsIntroduction-overview-warpper {
  padding: 2rem 0 0 0;
  margin: auto;
  width: auto;
  height: auto;
  background: #c8e7f2;
  overflow: auto;
  .img-container {
    position: relative;
    margin: auto;
    max-width: 1280px;
    &.mobile {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
    }
    .main-logo {
      position: absolute;
      left: 0;
      top: -3rem;
      width: 125px;
      height: 125px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .mask {
      margin: auto;
      width: auto;
      max-width: 1280px;
      height: 100vh;
      max-height: 720px;
      background: url("https://www.u-power.com.tw/photo/DSC00510.JPG") no-repeat
        center center;
      background-size: cover;
      -webkit-mask: url("../../assets//images/hero-img-mask.svg") no-repeat top
        center;
      mask: url("../../assets//images/hero-img-mask.svg") no-repeat top center;
      -webkit-mask-size: contain;
      mask-size: contain;
    }
    .event-date {
      position: absolute;
      right: 2.5rem;
      bottom: 1.5rem;
      width: fit-content;
      height: auto;
      font-size: 21px;
      font-weight: 700;
      line-height: 2rem;
    }
    .event-date.mobile {
      display: none;
    }
    @media screen and (max-width: 1320px) {
      margin: 0 1.5rem;
    }
    @media screen and (max-width: 576px) {
      display: none;
      margin: 0 1.5rem;
      &.mobile {
        display: block;
        .main-logo {
          position: static;
          display: block;
          margin: auto;
          margin-bottom: 1rem;
          width: 20vw;
          height: auto;
        }
        .event-date {
          position: static;
          display: block;
          margin: 1rem auto 0 auto;
          font-size: 20px;
          line-height: 36px;
        }
      }
    }
    @media screen and (max-width: 480px) {
      &.mobile {
        .event-date {
          font-size: 16px;
        }
      }
    }
    @media screen and (max-width: 400px) {
      &.mobile {
        .event-date {
          font-size: 13px;
        }
      }
    }
  }
  .main-info-section {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 1320px) {
      padding: 0 1.5rem;
    }
    .title {
      max-width: 900px;
      margin-top: 60px;
      font-size: 52px;
      font-weight: 800;
      line-height: 60px;
      text-align: center;
      @media screen and (max-width: 512px) {
        margin-top: 36px;
        font-size: 36px;
        line-height: 42px;
      }
    }
    .subtitle {
      max-width: 900px;
      position: relative;
      font-size: 32px;
      font-weight: 800;
      line-height: 36px;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -67px;
        width: 52px;
        height: 1px;
        background: rgba(33, 33, 33, 1);
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -67px;
        width: 52px;
        height: 1px;
        background: rgba(33, 33, 33, 1);
      }
      @media screen and (max-width: 880px) {
        font-size: 26px;
        line-height: 30px;
      }
      @media screen and (max-width: 712px) {
        max-width: 512px;
        font-size: 24px;
        line-height: 28px;
        overflow: hidden;
      }
      @media screen and (max-width: 512px) {
        font-size: 24px;
        line-height: 28px;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
    .subtitle-small {
      max-width: 900px;
      margin-bottom: 3rem;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      @media screen and (max-width: 512px) {
        margin-bottom: 1rem;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
      }
    }
    .bricks-img {
      max-width: 640px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .desc {
      max-width: 900px;
      margin-top: 3rem;
      p {
        font-size: 18px;
        line-height: 32.4px;
        font-weight: 400;
      }
      @media screen and (max-width: 512px) {
        margin-top: 1rem;
      }
    }
    .agenda {
      position: relative;
      margin-bottom: 3rem;
      width: 100%;
      max-width: 1280px;
      img.desktop {
        display: block;
        width: 100%;
        height: auto;
      }
      img.mobile {
        display: none;
      }
      .event {
        position: absolute;
        width: 31%;
        height: auto;
        right: 15%;
        bottom: 13%;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
          &.event-button {
            border-radius: 2rem;
          }
          &.text {
            width: 90%;
          }
        }
        &:hover {
          .event-button {
            box-shadow: 0 0 3px 3px #fff;
          }
        }
      }
      img {
        &.form {
          position: absolute;
          width: 31%;
          height: auto;
          right: 15%;
          bottom: 4.5%;
          border-radius: 2rem;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 3px 3px #fff;
          }
        }
      }
      @media screen and (max-width: 512px) {
        img.desktop {
          display: none;
        }
        img.mobile {
          display: block;
          width: 100%;
          height: auto;
        }
        .event {
          position: absolute;
          width: 80%;
          height: auto;
          right: 10%;
          bottom: 8%;
          border-radius: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
            &.event-button {
              border-radius: 2rem;
            }
            &.text {
              width: 90%;
            }
          }
          &:hover {
            .event-button {
              box-shadow: 0 0 3px 3px #fff;
            }
          }
        }
        img {
          &.form {
            position: absolute;
            width: 80%;
            height: auto;
            right: 10%;
            bottom: 3%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .autoshow-section {
    margin: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0rem;
    max-width: 900px;
    @media screen and (max-width: 972px) {
      margin: 3rem 1.5rem 0 1.5rem;
    }
    .title {
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }
    .subtitle {
      position: relative;
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -67px;
        width: 52px;
        height: 1px;
        background: rgba(33, 33, 33, 1);
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -67px;
        width: 52px;
        height: 1px;
        background: rgba(33, 33, 33, 1);
      }
    }
    @media screen and (max-width: 460px) {
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
      }
      .subtitle {
        font-size: 22px;
        font-weight: 500;
        line-height: 36px;
      }
    }
    .slide-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 98.5vw;
      overflow: visible;
      gap: 1rem;
      .title {
        margin-top: 3rem;
        position: relative;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1px;
        color: rgba(0, 150, 163, 1);
        img {
          position: absolute;
          left: -10%;
          top: -80%;
          width: 75px;
          height: auto;
        }
      }
      .slide-box {
        width: 100%;
        .autoshow-slide-photo {
          img {
            width: 100%;
            min-width: 100%;
            height: auto;
          }
        }
        @media screen and (max-width: 512px) {
          padding: 0 1.5rem;
        }
      }
      @media screen and (max-width: 580px) {
        .title {
          margin-top: 2rem;
          font-size: 24px;
          img {
            width: 65px;
            left: -10%;
            top: -50%;
          }
        }
      }
      @media screen and (max-width: 460px) {
        .title {
          margin-top: 3rem;
          font-size: 24px;
          img {
            width: 65px;
            left: -10%;
            top: -90%;
          }
        }
      }
    }
    .station-introduction-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 2rem;
      max-width: 900px;
      overflow: visible;
      gap: 1rem;
      .title {
        margin-top: 3rem;
        position: relative;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1px;
        color: rgba(0, 150, 163, 1);
        img {
          position: absolute;
          left: -10%;
          top: -80%;
          width: 75px;
          height: auto;
        }
        &::before {
          content: "電動車時代的核彈級戰略裝備";
          position: absolute;
          left: 50%;
          top: -70%;
          width: fit-content;
          height: auto;
          word-break: keep-all;
          font-size: 18px;
          letter-spacing: 1px;
          transform: translateX(-50%);
        }
      }

      .desc {
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 32.4px;
        }
      }
      .charging-table {
        margin-top: 1rem;
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contents-box {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .content {
          width: 40%;
          img {
            width: 100%;
            height: auto;
          }
        }
        @media screen and (max-width: 512px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          gap: 2rem;
          .content {
            width: 100%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      @media screen and (max-width: 580px) {
        .title {
          font-size: 24px;
          &::before {
            top: -60%;
            font-size: 14px;
          }
          img {
            width: 65px;
            left: -20%;
            top: -50%;
          }
        }
      }
      @media screen and (max-width: 460px) {
        .title {
          &::before {
            top: -60%;
            font-size: 14px;
          }
          img {
            width: 65px;
            left: -20%;
            top: -70%;
          }
        }
      }
    }
  }
  .map-section {
    position: relative;
    margin: auto;
    margin-top: 3rem;
    max-width: 1280px;
    cursor: pointer;
    @media screen and (max-width: 1320px) {
      margin: 0 1.5rem;
      margin-top: 3rem;
    }
    .map-box {
      width: 100%;
      .map {
        width: 100%;
        height: auto;
      }
      .search-icon {
        position: absolute;
        right: 2rem;
        bottom: 2rem;
        width: 50px;
        height: auto;
        @media screen and (max-width: 880px) {
          right: 1.5rem;
          bottom: 1.5rem;
          width: 30px;
        }
        @media screen and (max-width: 580px) {
          right: 1rem;
          bottom: 1rem;
          width: 20px;
        }
      }
    }
  }
  .cooperation-section {
    margin: 5rem 0 6rem 0;
    text-align: center;
    .title {
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 2.5rem;
    }
    .logos {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 790px;
        column-gap: 4rem;
        row-gap: 2rem;
      }
      @media screen and (max-width: 512px) {
        ul {
          column-gap: 2rem;
          row-gap: 1rem;
        }
      }
    }
    @media screen and (max-width: 880px) {
      margin: 5rem 1.5rem 6rem 1.5rem;
    }
  }
  .footer-section {
    padding: 1rem 0;
    background: #fff;
    .footer-container {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      @media screen and (max-width: 512px) {
        font-size: 13px;
      }
      @media screen and (max-width: 414px) {
        font-size: 12px;
      }
      @media screen and (max-width: 390px) {
        font-size: 10px;
      }
    }
  }
}
</style>
